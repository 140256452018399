import React, { useState, useRef, useEffect} from "react";
import "../assets/css/style.css";
import logo from "../assets/images/logo.png";
import { AiTwotoneHome } from "react-icons/ai";
import { FaUserAlt, FaUser, FaSignOutAlt } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaBloggerB } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa6";
import { TbPackages } from "react-icons/tb";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { SiGoogletranslate } from "react-icons/si";


const googleAuth = () => {
  window.open(`${process.env.REACT_APP_API_URL}/auth/google/callback`, "_self");
};

function NavbarSidebar({ show, setCookie, deleteCookie }) {
  const userName = localStorage.getItem("userName");
  const userPhoto = localStorage.getItem("userPhoto");
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const languageDropdown = useRef(null);


useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      languageDropdown.current &&
      !languageDropdown.current.contains(event.target)
    ) {
      setLanguageDropdownOpen(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

  return (
    <div
      className={`bg-color-secondary overflow-y-scroll z-10 duration-500 ${
        show ? "translate-x-full" : "translate-x-0"
      } flex items-start flex-col justify-start fixed h-full w-full top-0 right-0 sm:w-72 pb-12`}
    >
      <div className="flex w-full flex-col items-start justify-center">
        <a className="flex items-start justify-start cursor-pointer" href="/">
          <img src={logo} className="h-16 pl-2" alt="loading error" />
        </a>

        <hr className="border-t-1 w-full border-slate-400 mb-2" />

        {userPhoto === null && (
          <span className="flex items-center justify-center pl-4 py-2 gap-y-3 text-black">
            <span
              className="font-color-tertiary flex items-center gap-x-2.5 justify-center text-sm"
              onClick={googleAuth}
            >
              <span className="text-[1rem] text-black font-normal flex items-center justify-center cursor-pointer border-[1px] border-orange-400 p-1 rounded-md hover:bg-orange-500">
                <span className="cursor-pointer transition-all duration-300 font-bold">
                  Sign In
                </span>
                <FcGoogle className="ml-1 text-lg" />
              </span>
            </span>
          </span>
        )}
        {userPhoto && (
          <div className="flex flex-col pl-4 py-2 gap-y-3 text-black">
            <div className="flex justify-center items-center">
              <img
                src={
                  userPhoto
                    ? userPhoto
                    : `https://ui-avatars.com/api/?name=${userName}`
                }
                title="Profile"
                alt={userName}
                className="h-8 w-8 rounded-full mr-2"
              />
              <a
                className="flex items-center space-x-2  rounded font-semibold"
                href="/your-trips"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {userName}
              </a>
            </div>
          </div>
        )}

        <hr className="border-t-1 w-screen border-slate-400 mb-2" />

        {/* User Name / Login Section Complete  */}

        {/* Menu Options */}
        <ul className="font-color-tertiary flex flex-col text-gray-300 gap-y-3 items-start justify-center font-bold text-sm py-3 pl-4 w-[12rem]">
          <span className="cursor-pointer rounded-xl border-slate-400 border-[1px] p-2 w-full">
            <a
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
              href="/"
            >
              <AiTwotoneHome className="text-xl font-color-primary" /> Home
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/services"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <FaServicestack className="text-xl font-color-primary" /> Services
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/packages"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <TbPackages className="text-xl font-color-primary" /> Packages
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/community-trips"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <TbPackages className="text-xl font-color-primary" /> Community
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/contact-us"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <BiSolidPhoneCall className="text-xl font-color-primary" />{" "}
              Contact Us
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/blog"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <FaBloggerB className="text-xl font-color-primary" /> Blog
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <a
              href="/about"
              className="font-color-tertiary flex items-center gap-x-2.5 justify-start"
            >
              <FaUserAlt className="text-xl font-color-primary" /> About Us
            </a>
          </span>
          <span className="cursor-pointer rounded-xl border-slate-300 border-[1px] p-2 w-full">
            <div className="relative w-full">
              <button
                className="font-color-tertiary flex items-center gap-x-2.5 justify-start focus:outline-none w-full"
                type="button"
                onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
              >
                <SiGoogletranslate className="text-xl font-color-primary" />{" "}
                Language
              </button>
              {languageDropdownOpen && (
                <ul
                  className="dropdown-menu absolute scrollable left-0 mt-1 w-40 bg-gray-100 text-black rounded shadow-lg z-50"
                  ref={languageDropdown}
                >
                  <li>
                    <button
                      className="dropdown-item block px-2 py-2 w-full text-left hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        deleteCookie("googtrans");
                        //important
                        window.location.reload();
                      }}
                    >
                      English
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/hi`);
                        //important ಕನ್ನಡ
                        window.location.reload();
                      }}
                    >
                      हिन्दी (Hindi)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/kn`);
                        //important kannada
                        window.location.reload();
                      }}
                    >
                      ಕನ್ನಡ (Kannada)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/ta`);
                        //important tamil
                        window.location.reload();
                      }}
                    >
                      தமிழ் (Tamil)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/te`);
                        //important telugu
                        window.location.reload();
                      }}
                    >
                      తెలుగు (Telugu)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/bn`);
                        //important telugu
                        window.location.reload();
                      }}
                    >
                      বাংলা (Bengali)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/ml`);
                        //important telugu
                        window.location.reload();
                      }}
                    >
                      മലയാളം (Malayalam)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/gu`);
                        //important telugu
                        window.location.reload();
                      }}
                    >
                      ગુજરાતી (Gujarati)
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item block w-full text-left px-2 py-2 hover:bg-gray-300"
                      translate="no"
                      onClick={() => {
                        setCookie("googtrans", `/en/mr`);
                        //important telugu
                        window.location.reload();
                      }}
                    >
                      मराठी (Marathi)
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </span>
        </ul>

        <hr className="border-t-1 w-screen border-slate-400 pb-2" />

        {userPhoto && (
          <>
            <div className="flex flex-col items-start gap-y-3 pl-4 text-black font-bold text-sm py-3 w-[12rem]">
              <a
                className="flex items-center gap-x-2.5 justify-start rounded-xl border-slate-400 border-[1px] p-2 w-full"
                href="/your-trips"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <FaUser className="text-xl font-color-primary" />
                <span>Profile</span>
              </a>
              <button
                className="flex items-center gap-x-2.5 justify-start rounded-xl border-slate-400 border-[1px] p-2 w-54"
                onClick={() => {
                  localStorage.removeItem("userId");
                  localStorage.removeItem("userName");
                  localStorage.removeItem("userPhoto");
                  localStorage.removeItem("user");

                  window.location.reload();
                  window.open(
                    `${process.env.REACT_APP_API_URL}/auth/logout`,
                    "_self"
                  );
                }}
              >
                <FaSignOutAlt className="text-xl font-color-primary" />
                <span>Logout</span>
              </button>
            </div>
            <hr className="border-t-1 w-screen border-slate-400 mb-2 py-2" />
          </>
        )}
      </div>

      <span
        className="flex gap-y-3 flex-col font-semibold items-start justify-center pl-4 text-black"
        style={{ borderRight: "1px solid white" }}
      >
        <span className="flex gap-x-3 items-start justify-start">
          <FiPhoneCall className="text-md" />
          <span className="text-sm">
            <a href="tel:+918867627800">+91 88676-27800</a> <br />
          </span>
        </span>
        <span className="flex gap-x-3 items-start justify-start">
          <HiOutlineMail className="text-md" />
          <a
            href="mailto:contact@wandermewell.com"
            className="cursor-pointer text-sm"
          >
            contact@wandermewell.com
          </a>
        </span>
      </span>

      <hr className="border-t-1 mt-2 w-screen border-slate-400" />
    </div>
  );
}

export default NavbarSidebar;
