import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

export default function GetHotels(props) {
  const {
    hotels,
    onCoordinatesChange,
    setShowMap,
    setCurrentHotel,
    setShowCurrentHotelModal,
  } = props;
  const ratings = [
    "Bad",
    "Bad",
    "Below Average",
    "Average",
    "Average",
    "Good",
    "Good",
    "Very Good",
    "Excellent",
    "Exceptional",
    "Outstanding",
  ];

  const [hoveredActivity, setHoveredActivity] = useState(null);
  useEffect(() => {
    function handleActivityHover(hotel) {
      if (!hotel) return;
      let coordinates = {
        latitude: hotel.latitude,
        longitude: hotel.longitude,
      };
      if (coordinates) {
        onCoordinatesChange(coordinates);
      }
      setCurrentHotel(hotel);
      setShowCurrentHotelModal(true);
      setShowMap(true);
    }

    handleActivityHover(hoveredActivity);
  }, [hoveredActivity, onCoordinatesChange]);
  return (
    <>
      <div>
        <div className="flex flex-wrap flex-col gap-6">
          {hotels.map((hotel, index) => (
            <div key={"hotel-tab-top-div-" + index + "-" + hotel.hotel_id}>
              <div
                key={"hotel-tab-div-div-" + index + "-" + hotel.hotel_id}
                className="bg-white rounded-lg shadow-md flex md:flex-row flex-col w-full h-full"
              >
                <img
                  key={"hotel-tab-div-div-img-" + index + "-" + hotel.hotel_id}
                  src={
                    hotel.imageURL
                      ? hotel.imageURL
                      : hotel.photo1
                      ? hotel.photo1
                      : "http://pix5.agoda.net/hotelimages/231/2311/2311_16091419180046463138.jpg?s=312x&ca=6&ce=1"
                  }
                  alt={hotel.hotel_name}
                  className="h-[200px] w-full md:w-56 min-w-56 object-cover md:rounded-s-lg rounded-t-lg"
                />
                <div
                  key={"hotel-tab-div-div-div" + index + "-" + hotel.hotel_id}
                  className="p-2 min-h-40 h-auto w-full flex justify-between flex-row"
                >
                  <div
                    key={
                      "hotel-tab-div-div-div-div-1-" +
                      index +
                      "-" +
                      hotel.hotel_id
                    }
                    className="h-auto w-[62%] flex flex-col"
                  >
                    <div
                      key={
                        "hotel-tab-div-div-div-div-1-div-" +
                        index +
                        "-" +
                        hotel.hotel_id
                      }
                      className="flex justify-between w-full"
                    >
                      <h2
                        key={
                          "hotel-tab-div-div-div-div-div-h2-" +
                          index +
                          "-" +
                          hotel.hotel_id
                        }
                        className="md:text-lg text-md font-semibold mb-1"
                        onMouseEnter={() => setHoveredActivity(hotel)}
                        onMouseLeave={() => {
                          setHoveredActivity(null);
                          setCurrentHotel(null);
                        }}
                      >
                        {hotel.hotel_name}
                      </h2>
                    </div>
                    <h2
                      key={
                        "hotel-tab-div-div-div-div-1-h2-1-" +
                        index +
                        "-" +
                        hotel.hotel_id
                      }
                      className="text-xs md:text-sm font-normal"
                    >
                      Address : {hotel.addressline1}
                    </h2>
                    <h2
                      key={
                        "hotel-tab-div-div-div-div-1-h2-2-" +
                        index +
                        "-" +
                        hotel.hotel_id
                      }
                      className="text-xs md:text-sm font-normal"
                    >
                      {hotel.city}, {hotel.state}
                    </h2>
                    {hotel.yearopened && (
                      <h2
                        key={
                          "hotel-tab-div-div-div-div-1-h2-3-" +
                          index +
                          "-" +
                          hotel.hotel_id
                        }
                        className="text-xs md:text-sm font-normal"
                      >
                        Est. {hotel.yearopened}
                      </h2>
                    )}
                    {hotel.star_rating !== 0 && (
                      <div
                        key={
                          "hotel-tab-div-div-div-div-1-div-div-" +
                          index +
                          "-" +
                          hotel.hotel_id
                        }
                        className="flex items-center text-xs md:text-sm"
                      >
                        Ratings :
                        {hotel.star_rating >= 1 && (
                          <FaStar
                            key={
                              "hotel-tab-div-div-div-div-div-star-1-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="text-yellow-400 mx-0.5"
                          />
                        )}
                        {hotel.star_rating >= 2 && (
                          <FaStar
                            key={
                              "hotel-tab-div-div-div-div-div-star-2-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="text-yellow-400 mx-0.5"
                          />
                        )}
                        {hotel.star_rating >= 3 && (
                          <FaStar
                            key={
                              "hotel-tab-div-div-div-div-div-star-3-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="text-yellow-400 mx-0.5"
                          />
                        )}
                        {hotel.star_rating >= 4 && (
                          <FaStar
                            key={
                              "hotel-tab-div-div-div-div-div-star-4-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="text-yellow-400 mx-0.5"
                          />
                        )}
                        {hotel.star_rating >= 5 && (
                          <FaStar
                            key={
                              "hotel-tab-div-div-div-div-div-star-5-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="text-yellow-400 mx-0.5"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    key={
                      "hotel-tab-div-div-div-div-2-" +
                      index +
                      "-" +
                      hotel.hotel_id
                    }
                    className="flex flex-col w-[38%] h-auto justify-between"
                  >
                    <span
                      key={
                        "hotel-tab-div-div-div-div-2-span-" +
                        index +
                        "-" +
                        hotel.hotel_id
                      }
                      className="flex justify-end items-end"
                    >
                      {hotel.rating_average !== 0 && (
                        <span
                          key={
                            "hotel-tab-div-div-div-2-span-span-" +
                            index +
                            "-" +
                            hotel.hotel_id
                          }
                          className="gap-1 flex flex-row justify-center items-center"
                        >
                          <span
                            key={
                              "hotel-tab-div-div-div-2-span-span-span-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="flex flex-col justify-end items-end"
                          >
                            <span
                              key={
                                "hotel-tab-div-div-div-2-span-span-span-span-1-" +
                                index +
                                "-" +
                                hotel.hotel_id
                              }
                              className="text-xs font-semibold"
                            >
                              {ratings[Math.floor(hotel.rating_average)]}
                            </span>
                            <span
                              key={
                                "hotel-tab-div-div-div-2-span-span-span-span-2-" +
                                index +
                                "-" +
                                hotel.hotel_id
                              }
                              className="text-xs font-semibold"
                            >
                              ({hotel.number_of_reviews})
                            </span>
                          </span>
                          <span
                            key={
                              "hotel-tab-div-div-div-2-span-span-2-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className={`text-sm font-black rounded-md p-2 h-10 w-10 flex items-center justify-center ${
                              hotel.rating_average >= 8
                                ? "bg-green-600"
                                : hotel.rating_average >= 6
                                ? "bg-green-400"
                                : hotel.rating_average >= 4
                                ? "bg-yellow-500"
                                : "bg-red-500"
                            } text-white`}
                          >
                            {" "}
                            {hotel.rating_average}
                          </span>
                        </span>
                      )}
                    </span>
                    <div
                      key={
                        "hotel-tab-div-div-div-2-" +
                        index +
                        "-" +
                        hotel.hotel_id
                      }
                    >
                      <div
                        key={
                          "hotel-tab-div-div-div-2-div-" +
                          index +
                          "-" +
                          hotel.hotel_id
                        }
                        className="flex justify-end items-end pt-2"
                      >
                        {hotel.price ? (
                          <div
                            key={
                              "hotel-tab-div-div-div-2-div-div-" +
                              index +
                              "-" +
                              hotel.hotel_id
                            }
                            className="flex flex-col w-fit"
                          >
                            {hotel.crossedOutRate ? (
                              <div
                                key={
                                  "hotel-tab-div-div-div-2-div-div-div-" +
                                  index +
                                  "-" +
                                  hotel.hotel_id
                                }
                                className="relative flex justify-end items-end"
                              >
                                <span
                                  key={
                                    "hotel-tab-div-div-div-2-div-div-div-span-1-" +
                                    index +
                                    "-" +
                                    hotel.hotel_id
                                  }
                                  className="font-bold bg-red-700 text-white rounded-md px-1 text-xs mr-1"
                                >
                                  {Math.round(
                                    ((hotel.crossedOutRate - hotel.price) /
                                      hotel.crossedOutRate) *
                                      100
                                  )}
                                  % off
                                </span>
                                <span
                                  key={
                                    "hotel-tab-div-div-div-2-div-div-div-span-2-" +
                                    index +
                                    "-" +
                                    hotel.hotel_id
                                  }
                                  className="text-end font-black text-red-700 text-xs"
                                >
                                  {hotel.crossedOutRate} {hotel.currency}
                                </span>
                                <div
                                  key={
                                    "hotel-tab-div-div-div-2-div-div-div-div-" +
                                    index +
                                    "-" +
                                    hotel.hotel_id
                                  }
                                  className="absolute top-1/2 right-0 w-20 h-0.5 bg-red-700 transform -rotate-12"
                                  style={{ transformOrigin: "center" }}
                                ></div>
                              </div>
                            ) : null}
                            <span
                              key={
                                "hotel-tab-img-" + index + "-" + hotel.hotel_id
                              }
                              className="text-black font-black py-2 text-xs w-fit cursor-pointer duration-200"
                            >
                              Starts from{" "}
                              <span
                                key={
                                  "hotel-tab-img-" +
                                  index +
                                  "-" +
                                  hotel.hotel_id
                                }
                                className="md:text-lg text-sm text-green-600"
                              >
                                {" "}
                                {hotel.price} {hotel.currency}{" "}
                              </span>
                            </span>
                          </div>
                        ) : (
                          <span
                            key={
                              "hotel-tab-img-" + index + "-" + hotel.hotel_id
                            }
                            className="text-red-600 font-black py-3 text-sm w-fit cursor-pointer duration-200"
                          >
                            Price Not Available
                          </span>
                        )}
                      </div>
                      <div
                        key={"hotel-tab-img-" + index + "-" + hotel.hotel_id}
                        className="flex justify-end items-end"
                      >
                        <button
                          key={"hotel-tab-img-" + index + "-" + hotel.hotel_id}
                          className="text-white font-bold py-2 px-3 text-sm w-fit rounded-md border border-[#00000096] cursor-pointer bg-green-700 hover:bg-green-600 duration-200"
                        >
                          <a
                            href={hotel.landingURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View More
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
