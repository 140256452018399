import React from "react";
import { IoMdClose as Close } from "react-icons/io";
import { FaMapMarkerAlt, FaGlobe } from "react-icons/fa";

function ActivityDetails({
  activity,
  showActivityModal,
  setShowActivityModal,
}) {
  return (
    <>
      {activity ? (
        <div
          className={`activityDetails fixed pb-5 z-10 bottom-0 flex flex-col items-end justify-center gap-y-1 w-full px-2.5 py-2.5 rounded-md lg:absolute ${
            !showActivityModal
              ? "translate-y-full duration-100"
              : "translate-y-0 duration-500"
          }`}
        >
          <span
            className="flex items-center justify-center rounded-full h-10 w-10 cursor-pointer bg-white"
            onClick={() => setShowActivityModal(false)}
          >
            <Close className="text-2xl cursor-pointer font-extrabold text-gray-500" />
          </span>
          {activity ? (
            <div className="flex flex-col px-5 py-3 items-start justify-start h-70 overflow-y-scroll w-full bg-white rounded-md pb-9 md:pb-6 lg:pb-3">
              <div className="flex flex-col items-start justify-start w-full">
                <span className="flex gap-x-1 gap-y-1 items-center justify-start w-full">
                  <span className="text-md font-semibold text-gray-700">
                    {activity.activity}
                  </span>
                  <span> </span>
                  <span className="text-sm text-black rounded-3xl px-1 bg-green-400">
                    {activity.primaryTypeDisplayName?.text ||
                      activity.activityTypes[0]}
                  </span>
                </span>
                <span className="text-sm text-slate-400">
                  {activity.formattedAddress ||
                    activity.placeName + activity.placeFormatted}
                </span>
              </div>
              <span className="text-base lg:text-sm ">{activity.remarks}</span>
              <span className="text-base lg:text-sm ">
                Opening Hours :- {activity.openingHours}
              </span>
              <span className="text-base lg:text-sm ">
                Duration :- {activity.duration}
              </span>
              <span className="text-base lg:text-sm ">
                Cost :- ₹{activity.entryFees} INR.
              </span>
              <div className="flex gap-x-2 justify-start items-start text-sm">
                {activity.googleMapsUri && (
                  <a
                    className="flex items-center p-1 bg-purple-600 text-white rounded-3xl shadow-sm cursor-pointer"
                    href={activity.googleMapsUri}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaMapMarkerAlt className="mr-2" />
                    Maps
                  </a>
                )}
                {activity.websiteUri && (
                  <a
                    className="flex items-center p-1 bg-purple-600 text-white rounded-3xl shadow-sm cursor-pointer"
                    href={activity.websiteUri}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGlobe className="mr-2" />
                    Website
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-y-7 px-5 py-3.5 items-start justify-start h-64 overflow-y-scroll w-full bg-white rounded-md">
              Sorry activity details are not available !
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ActivityDetails;
