import { createContext, useContext, useRef, useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import { IoLocation as Location } from "react-icons/io5";

const AccordianContext = createContext();

export default function Accordian({ children, value, onChange, ...props }) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    onChange?.(selected);
  }, [selected, onChange]);

  return (
    <ul {...props}>
      <AccordianContext.Provider value={{ selected, setSelected }}>
        {children}
      </AccordianContext.Provider>
    </ul>
  );
}

export function AccordianItem({ index, children, value, trigger, ...props }) {
  const { selected, setSelected } = useContext(AccordianContext);
  const open = selected === value;

  const ref = useRef(null);
  const colors = ["#f87171", "#c084fc", "#4ade80", "#f472b6", "#fb923c"];
  return (
    <li
      className="border-b-[1px] border-solid border-gray-200 bg-white"
      {...props}
    >
      <header
        role="button"
        onClick={() => setSelected(open ? null : value)}
        className="flex justify-between items-center p-4 font-medium"
      >
        <span className="text-lg font-normal text-gray-600 text-center align-middle sm:text-xl">
          <Location
            className={`text-lg inline-block mr-1 mb-1`}
            style={{ color: colors[index % 5] }}
          />{" "}
          {trigger}
        </span>
        <ChevronDown
          size={28}
          className={`transition-transform text-gray-600 ${
            open ? "rotate-180" : ""
          }`}
        />
      </header>
      <div
        className="overflow-y-hidden transition-all"
        style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
      >
        <div className="pt-2 p-4" ref={ref}>
          {children}
        </div>
      </div>
    </li>
  );
}
