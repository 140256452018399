import React from "react";
import { IoMdClose as Close } from "react-icons/io";
import { FaStar } from "react-icons/fa";

function HotelDetails({
  currentHotel,
  showCurrentHotelModal,
  setShowCurrentHotelModal,
}) {
  return (
    <>
      {currentHotel ? (
        <div
          className={`activityDetails fixed pb-5 z-10 bottom-0 flex flex-col items-end justify-center gap-y-1 w-full px-2.5 py-2.5 rounded-md lg:absolute ${
            !showCurrentHotelModal
              ? "translate-y-full duration-100"
              : "translate-y-0 duration-500"
          }`}
        >
          <span
            className="flex items-center justify-center rounded-full h-10 w-10 cursor-pointer bg-white"
            onClick={() => setShowCurrentHotelModal(!showCurrentHotelModal)}
          >
            <Close className="text-2xl cursor-pointer font-extrabold text-gray-500" />
          </span>
          {currentHotel ? (
            <div className="flex flex-col px-5 py-3 items-start justify-start h-70 overflow-y-scroll w-full bg-white rounded-md pb-9 md:pb-6 lg:pb-3">
              <div className="w-full flex flex-col items-start justify-between">
                <h1 className="text-md font-semibold text-gray-700">
                  {currentHotel.hotel_name}
                </h1>
                <span className="text-sm text-gray-500 font-normal">
                  {currentHotel.addressline1}
                </span>
                {currentHotel.addressline2 && (
                  <span className="text-sm text-gray-500 font-normal ">
                    {currentHotel.addressline2}
                  </span>
                )}
                <div className="flex my-2 gap-2">
                  <span className="text-sm font-semibold px-3 py-1 rounded-full bg-green-600 text-white">
                    {currentHotel.accommodation_type}
                  </span>
                  <span
                    className={`text-sm font-semibold px-3 py-1 rounded-full ${
                      currentHotel.freeWifi
                        ? "bg-green-600"
                        : "bg-red-600 line-through"
                    } text-white`}
                  >
                    Free Wifi
                  </span>
                  <span
                    className={`text-sm font-semibold px-3 py-1 rounded-full ${
                      currentHotel.includeBreakfast
                        ? "bg-green-600"
                        : "bg-red-600 line-through"
                    } text-white`}
                  >
                    Breakfast
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-nowrap overflow-x-scroll justify-start items-center hidden-scrollbar py-1">
                <img
                  src={
                    currentHotel.photo1
                      ? currentHotel.photo1
                      : "http://pix2.agoda.net/hotelimages/46867425/-1/aa57db5c805b0c18c8450ed7b2164ca8.jpg?s=312x&ce=0"
                  }
                  //onClick={() => setPicture(currentHotel.photo1)}
                  alt="HotelName"
                  className="w-20 h-20 m-1 rounded-md"
                />
                <img
                  src={
                    currentHotel.photo2
                      ? currentHotel.photo2
                      : "http://pix2.agoda.net/hotelimages/46867425/-1/aa57db5c805b0c18c8450ed7b2164ca8.jpg?s=312x&ce=0"
                  }
                  //onClick={() => setPicture(currentHotel.photo2)}
                  alt="HotelName"
                  className="w-20 h-20 m-1 rounded-md"
                />
                <img
                  src={
                    currentHotel.photo3
                      ? currentHotel.photo3
                      : "http://pix2.agoda.net/hotelimages/46867425/-1/aa57db5c805b0c18c8450ed7b2164ca8.jpg?s=312x&ce=0"
                  }
                  //onClick={() => setPicture(currentHotel.photo3)}
                  alt="HotelName"
                  className="w-20 h-20 m-1 rounded-md"
                />
                <img
                  src={
                    currentHotel.photo4
                      ? currentHotel.photo4
                      : "http://pix2.agoda.net/hotelimages/46867425/-1/aa57db5c805b0c18c8450ed7b2164ca8.jpg?s=312x&ce=0"
                  }
                  //onClick={() => setPicture(currentHotel.photo4)}
                  alt="HotelName"
                  className="w-20 h-20 m-1 rounded-md"
                />
              </div>
              <div className="details w-full h-auto flex flex-wrap py-1">
                <h6 className="text-sm font-norma w-1/2">
                  Pincode : {currentHotel.zipcode}
                </h6>
                <h6 className="text-sm font-normal w-1/2">
                  {currentHotel.star_rating !== 0 && (
                    <div className="flex items-center text-xs md:text-sm">
                      Ratings :
                      {currentHotel.star_rating >= 1 && (
                        <FaStar className="text-yellow-400 mx-0.5" />
                      )}
                      {currentHotel.star_rating >= 2 && (
                        <FaStar className="text-yellow-400 mx-0.5" />
                      )}
                      {currentHotel.star_rating >= 3 && (
                        <FaStar className="text-yellow-400 mx-0.5" />
                      )}
                      {currentHotel.star_rating >= 4 && (
                        <FaStar className="text-yellow-400 mx-0.5" />
                      )}
                      {currentHotel.star_rating >= 5 && (
                        <FaStar className="text-yellow-400 mx-0.5" />
                      )}
                    </div>
                  )}
                </h6>
                <h6 className="text-sm font-normal w-1/2">
                  Check In : {currentHotel.checkin}
                </h6>
                <h6 className="text-sm font-normal w-1/2">
                  Check Out: {currentHotel.checkout}
                </h6>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-y-7 px-5 py-3.5 items-start justify-start h-64 overflow-y-scroll w-full bg-white rounded-md">
              Sorry hotel details are not available !
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default HotelDetails;
