import React, { useEffect, useState } from "react";
import Accordian, { AccordianItem } from "./Accordion";
// import { FaGripLines, FaTrash, FaShareAlt } from "react-icons/fa";

export default function TripItinerary({
  date,
  itinerary,
  onCoordinatesChange,
  setActivity,
  setShowActivityModal,
  setShowMap,
}) {
  const [hoveredActivity, setHoveredActivity] = useState(null);

  const WeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const startingDate = new Date(date);

  // get Week Day
  const getDay = (index) => {
    return WeekDays[(startingDate.getDay() + index) % 7];
  };

  // get Month
  const getMonth = (index, month) => {
    if (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 8 ||
      month === 10
    ) {
      const date = startingDate.getDate() + index;
      return date <= 30
        ? Months[startingDate.getMonth() % 12]
        : Months[(startingDate.getMonth() + 1) % 12];
    } else {
      const date = startingDate.getDate() + index;
      return date <= 31
        ? Months[startingDate.getMonth() % 12]
        : Months[(startingDate.getMonth() + 1) % 12];
    }
  };

  // get Date
  const getDate = (index, month) => {
    if (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 8 ||
      month === 10
    ) {
      const date = startingDate.getDate() + index;
      return date <= 30 ? date : date % 30;
    } else {
      const date = startingDate.getDate() + index;
      return date <= 31 ? date : date % 31;
    }
  };

  const handleActivityDetails = (givenActivity) => {
    setShowMap(true);
    setShowActivityModal(true);
    setActivity(givenActivity);
  };

  useEffect(() => {
    function handleActivityHover(activity) {
      if (!activity) return;
      let coordinates = activity.location;
      if (coordinates) {
        onCoordinatesChange(coordinates);
      }
    }

    handleActivityHover(hoveredActivity);
  }, [hoveredActivity, onCoordinatesChange]);

  return (
    itinerary && (
      <div className="tripItinerary pb-8 md:pb-10 lg:pb-2">
        <Accordian
          flush
          style={{ backgroundColor: "transparent" }}
          defaultActiveKey="0"
        >
          {itinerary.map((item, index) => (
            <AccordianItem
              style={{ backgroundColor: "transparent" }}
              index={index}
              trigger={`${getDay(index)}, ${getMonth(
                index,
                startingDate.getMonth()
              )}, ${getDate(index, startingDate.getMonth())}`}
              value={index}
              eventKey={index}
              key={"accordian-item-" + index}
            >
              {item.activities &&
                item.activities.map((activity, index) => (
                  <div className="flex justify-start items-start">
                    <div
                      // className="group relative p-1 shadow-md shadow-gray-500 flex justify-between items-start m-2 w-11/12 rounded-lg"
                      className="group relative p-1 shadow-sm shadow-gray-500 flex justify-between items-start w-full rounded-lg mb-2"
                      key={"item-activity-div-" + index}
                    >
                      <div
                        className="container text-sm flex-row"
                        style={{
                          // backgroundColor: "#80808057",
                          borderRadius: "45",
                        }}
                        key={"activity-body-div-" + { index }}
                      >
                        <h5
                          className="text-lg font-semibold"
                          style={{
                            color: "#fd630d",
                          }}
                          key={"activity-header-" + { index }}
                        >
                          <span
                            className="hover:cursor-pointer font-semibold"
                            key={"activity-header-span-" + { index }}
                            onMouseEnter={() => setHoveredActivity(activity)}
                            onMouseLeave={() => setHoveredActivity(null)}
                            onClick={() => handleActivityDetails(activity)}
                          >
                            {activity.activity}
                          </span>
                        </h5>
                        <p key={"activity-body-remarks-" + { index }}>
                          {activity.remarks}
                        </p>
                        <span
                          className="font-semibold"
                          key={"activity-body-time-" + { index }}
                        >
                          {activity.timings}:
                        </span>{" "}
                        <span
                          className="font-semibold"
                          key={"activity-body-duration-" + { index }}
                        >
                          ({activity.duration}){" "}
                        </span>
                        <span
                          className="font-semibold"
                          key={"activity-body-cost-" + { index }}
                        >
                          {activity.entryFees !== 0
                            ? "Cost :- ₹" + activity?.entryFees + " INR."
                            : "Free"}
                        </span>
                      </div>
                      <div
                        className="group/buttons hidden relative my-2 ml-0.5 md:flex min-h-[100px] min-w-[100px] w-full cursor-pointer flex-row-reverse gap-3 rounded-lg"
                        key={"activity-body-div-image-" + { index }}
                      >
                        {activity?.photos?.[0] && (
                          <img
                            className="pointer-events-none hidden h-12 w-32 min-w-[5rem] rounded-lg bg-cover bg-center object-cover shadow-sm md:block md:h-24"
                            loading="lazy"
                            alt=""
                            width="100"
                            height="100"
                            decoding="async"
                            data-nimg="1"
                            style={{ color: "transparent" }}
                            src={`https://places.googleapis.com/v1/${activity.photos[0].name}/media?maxHeightPx=400&maxWidthPx=600&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></img>
                        )}
                      </div>
                      <hr key={"activity-hr-" + { index }} className="w-25" />
                      <br key={"activity-br-" + { index }} />
                    </div>
                    {/* <div className="flex flex-col items-center gap-1 mt-2">
                      <button
                        className="flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
                        title="Drag"
                      >
                        <FaGripLines />
                      </button>
                      <button
                        className="flex items-center justify-center w-6 h-6 bg-red-200 rounded-full hover:bg-red-300 focus:outline-none"
                        title="Delete"
                      >
                        <FaTrash />
                      </button>
                      <button
                        className="flex items-center justify-center w-6 h-6 bg-blue-200 rounded-full hover:bg-blue-300 focus:outline-none"
                        title="Share"
                      >
                        <FaShareAlt />
                      </button>
                    </div> */}
                  </div>
                ))}
              Total cost for the day - ₹{item.totalCostOfDay} INR.
            </AccordianItem>
          ))}
        </Accordian>
      </div>
    )
  );
}
